import React, { Component } from 'react';
import Article from '../article';
import IMG from '../../../assets/img/mobile-retter.jpeg';
import { Helmet } from 'react-helmet';

class Herzrettung extends Component {
    state = {}
    render() {
        return (
            <>
                <Helmet>
                    <title>Mobile Herzrettung in Wuppertal etabliert - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Mobile Herzrettung in Wuppertal etabliert', veröffentlicht am Freitag, 09.02.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel beleuchtet die Bemühungen der Initiative, die erste Hilfe zu verbessern und die Mobile Retter in Wuppertal zu etablieren. Erfahren Sie, wie die Herzinitiative in Zusammenarbeit mit Mobile Retter e.V. und dem Rettungsdienst der Stadt Wuppertal das Smartphone-basierte System zur Herzrettung erfolgreich implementiert hat." />
                </Helmet>

                <Article
                    title={"Mobile Herzrettung in Wuppertal etabliert"}
                    date={"Freitag, 09.02.2024"}
                    tag1={"Arbeit"}
                    tag2={"Herzrettung"}
                    image={IMG}
                    sections={
                        [
                            "Erste Hilfe zu verbessern war ein Hauptbeweggrund für die Bildung der Wuppertaler Herzinitiative. Möglichst viele Menschen sollten in die Lage Rücksichtnahmen zu nehmen und die Notwendigkeit versetzt werden, unverzüglich und sachgerecht im Notfall erste Hilfe leisten zu können. Und es im Notfall auch zu tun. Letztendlich entschied sich der Verein aber für eine Kooperation mit Mobile Retter e.V. und eine daraus resultierende Zusammenarbeit mit dem gut funktionierenden Rettungsdienst der Stadt Wuppertal.",
                            "Obwohl dieses Smartphone-basierte System schon in anderen Regionen, anderen Städten und Gebietskörperschaften erfolgreich eingesetzt wurde, benötigte es unerwartet viel Zeit, um auch im Bergischen Land „Mobile Retter“ erfolgreich tätig werden zu lassen. Maßgeblich war insbesondere die Notwendigkeit, die hinderliche hiesige finanzielle Situation zu meistern. Letztendlich entschied sich die Herzinitiative e.V. die Startfinanzierung und die operativen Arbeiten zu übernehmen. Beitragszahler, Spender und Sponsoren schafften also die finanzielle Basis – Mitglieder des Vereins und andere Ehrenamtler leisteten erhebliche operative Arbeit um die regionale Konzeption zum Laufen zu bringen.",
                            "Um Mobile Retter auch in der Bergischen Metropole aktiv werden zu lassen, waren nicht allein erhebliche technische und organisatorische Voraussetzungen  zu schaffen. Die Gewinnung einer ausreichenden Zahl von qualifizierten ehrenamtlichen Herzretterinnen und Herzrettern – bei der Größe Wuppertals sind das 700 – erwies sich als großes Problem (es ist noch nicht ganz gemeistert). Zu Jahresanfang 2024 arbeiteten ca. 500 Menschen in Wuppertal als Herzretter. Und Erfolgreich und in hervorragender Partnerschaft mit dem Rettungsdienst der Stadt.",
                            "Die Wuppertaler Herzinitiative e.V. konnte sich aus der mit besonderen sachlichen und finanziellen Mitteln sowie und mit speziellem Knowhow geleisteten Starthilfe lösen; das System ist im Echtbetrieb, es funktioniert und ist etabliert. Die Stadt Wuppertal trifft in die Pflichten ein."
                        ]
                    }
                    overview={"aus-unserer-arbeit-alt"}
                    showAlert={true}
                />
            </>
        );
    }
}

export default Herzrettung;
