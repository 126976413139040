import React, { Component } from 'react';

class AboutPosts extends Component {
    state = {  } 
    render() { 
        return (
            <section className="py-5 bg-body-tertiary">
                <div className="container px-5">
                    <h2 className="fw-bolder fs-5 mb-4 text-center text-muted mb-5">
                        Neueste Beiträge
                    </h2>
                    <div className="row gx-5 d-flex justify-content-center align-items-stretch">
                        <div className="col-lg-4 mb-5">
                            <div className="card shadow border-0 h-100">
                                <div className="card-body p-4">
                                    <div className="badge bg-primary rounded-pill mb-2">Beitrag</div>
                                    <a
                                        className="text-decoration-none link-dark stretched-link"
                                        href="https://www.radiowuppertal.de/artikel/herzretterinnen-gesucht-1330096.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="h5 card-title">Wir helfen Herzen retten</div>

                                        <div className="d-flex align-items-center mb-1">
                                            <div className="small">
                                                <div className="text-muted">Freitag, 12.04.2024</div>
                                            </div>
                                        </div>

                                    </a>
                                    <p className="card-text mb-0">
                                        2022 haben wir das Smartphone-basierte Mobile Retter-Programm nach Wuppertal geholt, es organisiert und finanziert. Inzwischen ist es nicht allein in Wuppertal etabliert (...)
                                    </p>

                                    <a className="btn btn-primary btn-sm text-white mt-4" href="erste-hilfe-uebernehmen">
                                        Mehr lesen!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card shadow border-0 h-100">
                                <div className="card-body p-4">
                                    <div className="badge bg-primary rounded-pill mb-2">Artikel</div>
                                    <a
                                        className="text-decoration-none link-dark stretched-link"
                                        href="https://www.wuppertal.de/presse/meldungen/meldungen-2022/juni/herzretter.php"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="h5 card-title mb-3">
                                            Schnelle Hilfe mit System: Mobile Retter am Start
                                        </div>
                                    </a>
                                    <p className="card-text mb-0">
                                        100 Mobile Retter sind ab sofort im Einsatz: Der Verein
                                        Wuppertaler Herzinitiative hat ehrenamtliche Mitarbeiter gesucht
                                        und gefunden.
                                    </p>
                                </div>
                                <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="small">
                                                <div className="fw-bold">Stadt Wuppertal</div>
                                                <div className="text-muted">Dienstag, 14.06.2022</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutPosts;