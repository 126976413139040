import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class FAQ extends Component {
    constructor(props) {
        super(props);

        // Set initial states for each accordion group
        this.state = {
            activeAccordionGroup1: 1,
            activeAccordionGroup2: 4,
        };
    }

    handleAccordionClickGroup1 = (index) => {
        this.setState((prevState) => ({
            activeAccordionGroup1: prevState.activeAccordionGroup1 === index ? null : index,
        }));
    };

    handleAccordionClickGroup2 = (index) => {
        this.setState((prevState) => ({
            activeAccordionGroup2: prevState.activeAccordionGroup2 === index ? null : index,
        }));
    };

    render() {
        return (
            <section className="faq">
                <Helmet>
                    <title>FAQ - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Antworten auf häufig gestellte Fragen (FAQ) über die Wuppertaler Herzinitiative e.V. Finden Sie hier Informationen zu unseren Aktivitäten, Mitgliedschaft, Spenden, Veranstaltungen und vielem mehr. Erfahren Sie mehr über unsere Organisation und wie Sie sich beteiligen können." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">
                                Fragen &amp; Antworten
                            </h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-xl-8 ">
                            {/* FAQ Accordion 1*/}
                            <h2 className="fw-bolder mb-3">
                                Mitgliedschaft &amp; Mitarbeit
                            </h2>
                            <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                            <div className="accordion mb-5">
                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="headingOne">
                                        <button
                                            className={`accordion-button ${this.state.activeAccordionGroup1 === 1 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => this.handleAccordionClickGroup1(1)}
                                        >
                                            Wie kann ich Mitglied der Wuppertaler Herzinitiative e.V. werden?
                                        </button>
                                    </h3>
                                    <div
                                        className={`accordion-collapse collapse ${this.state.activeAccordionGroup1 === 1 ? 'show' : ''}`}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <strong>Antwort: </strong>
                                            Die Mitgliedschaft in der Herzinitiative ist entscheidend für
                                            die Verwirklichung unserer Ziele. Wir laden alle interessierten
                                            Bürgerinnen und Bürger herzlich dazu ein, Mitglied zu werden.
                                            Auch Unternehmen und Organisationen sind willkommen, der
                                            Herzinitiative beizutreten.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item ">
                                    <h3 className="accordion-header" id="headingTwo">
                                        <button
                                            className={`accordion-button ${this.state.activeAccordionGroup1 === 2 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => this.handleAccordionClickGroup1(2)}
                                        >
                                            Wie können Ärztinnen, Ärzte und Angehörige anderer Heilberufe
                                            mitwirken?
                                        </button>
                                    </h3>
                                    <div
                                        className={`accordion-collapse collapse ${this.state.activeAccordionGroup1 === 2 ? 'show' : ''}`}
                                        id="collapseTwo"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <strong>Antwort: </strong>
                                            Speziell für Ärztinnen, Ärzte und Angehörige anderer Heilberufe
                                            gibt es die Möglichkeit, bei der Herzinitiative mitzuwirken. Für
                                            detaillierte Informationen steht Ihnen unser Vorstand,
                                            insbesondere Prof. Gülker, gerne zur Verfügung. Kontaktieren Sie
                                            uns bitte!
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item ">
                                    <h3 className="accordion-header" id="headingThree">
                                        <button
                                            className={`accordion-button ${this.state.activeAccordionGroup1 === 3 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => this.handleAccordionClickGroup1(3)}
                                        >
                                            Wie kann ich die Herzinitiative finanziell unterstützen?
                                        </button>
                                    </h3>
                                    <div
                                        className={`accordion-collapse collapse ${this.state.activeAccordionGroup1 === 3 ? 'show' : ''}`}
                                        id="collapseThree"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <strong>Antwort: </strong>
                                            Zur Erreichung unserer Ziele benötigen wir dringend finanzielle
                                            Unterstützung, um diese zu realisieren. Ihre Spende trägt dazu
                                            bei, die Herzgesundheit zu verbessern und die Notfallversorgung
                                            bei Herzstillstand zu optimieren. Sie können Ihre Spende auf
                                            unser Konto bei der Stadtsparkasse Wuppertal überweisen
                                            <code>(DE81 3305 0000 0000 8293 33)</code>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FAQ Accordion 2*/}
                            <h2 className="fw-bolder mb-3">
                                Notfallverhalten
                            </h2>
                            <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                            <div
                                className="accordion mb-5 mb-xl-0"
                                id="accordionExample2"
                            >
                                <div className="accordion-item">
                                    <h3 className="accordion-header" id="secondheadingOne">
                                        <button
                                            className={`accordion-button ${this.state.activeAccordionGroup2 === 4 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => this.handleAccordionClickGroup2(4)}
                                        >
                                            Gibt es eine Möglichkeit, direkt erste Hilfe zu leisten?
                                        </button>
                                    </h3>
                                    <div
                                        className={`accordion-collapse collapse ${this.state.activeAccordionGroup2 === 4 ? 'show' : ''}`}
                                        id="secondcollapseOne"
                                        aria-labelledby="secondheadingOne"
                                        data-bs-parent="#secondaccordionExample"
                                    >
                                        <div className="accordion-body">
                                            <strong>Antwort: </strong>
                                            Ja, im Notfall zählt jede Minute. Rufen Sie die <code>
                                                112
                                            </code>{" "}
                                            an und leisten Sie möglichst Erste Hilfe (prüfen, rufen,
                                            drücken) oder organisieren Sie Hilfe. Wir danken für Ihre
                                            tatkräftige Unterstützung. Bitte vergessen Sie nicht, den
                                            112-Notruf anzurufen, um im medizinischen Notfall
                                            schnellstmöglich Hilfe zu erhalten.
                                        </div>
                                    </div>
                                </div>
                                {/* ... (repeat for other accordion items in Accordion 2) */}
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card border-0 bg-body-tertiary mt-xl-5">
                                <div className="card-body p-4 py-lg-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="text-center">
                                            <div className="h6 fw-bolder">Weitere Fragen?</div>
                                            <p className="text-muted mb-4">
                                                Kontaktieren Sie uns:
                                                <br />
                                                <a
                                                    className="nav-link cb-mail text-primary"
                                                    href="mailto:INFO@WUPPERTALER-HERZINITIATIVE.DE"
                                                >
                                                    info@wuppertaler-herzinitiative.de
                                                </a>
                                            </p>
                                            <div className="h6 fw-bolder">Vielen Dank!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FAQ;
