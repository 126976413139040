import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Imprint extends Component {
    state = {  } 
    render() { 
        return (
            <section className="imprint">
                <Helmet>
                    <title>Impressum - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über das Impressum der Wuppertaler Herzinitiative e.V. Hier finden Sie rechtliche Informationen über die Organisation, wie Kontaktinformationen, rechtliche Vertreter und weitere gesetzlich vorgeschriebene Angaben gemäß deutschem Telemediengesetz (TMG)." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">
                                Impressum
                            </h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="contact-info text-left">
                            <h6>Angaben gemäß § 5 TMG</h6>
                            <ul className="list-unstyled">
                                <li>Wuppertaler Herzinitiative e.V.</li>
                                <li>c/o Klaus H. Richter</li>
                                <li>Briller Höhe 8</li>
                                <li>42115 Wuppertal</li>
                            </ul>
                            <ul className="list-unstyled">
                                <li>Vereinsregister: VR30856</li>
                                <li>Registergericht: Amtsgericht Wuppertal</li>
                            </ul>
                            <h6>Vertreten durch:</h6>
                            <ul className="list-unstyled">
                                <li>Prof. Dr. Hartmut Gülker</li>
                                <li>Rüdiger Neumann</li>
                                <li>Susanne Schönrock</li>
                                <li>Josef Achilles</li>
                                <li>Helge Lindh</li>
                            </ul>
                            <h6>Kontakt</h6>
                            <ul className="list-unstyled">
                                <li>Telefon: +49 (0) 202 459 58 99</li>
                                <li />
                                E-Mail:{" "}
                                <a href="mailto:info@wuppertaler-herzinitiative.de">
                                    info@wuppertaler-herzinitiative.de
                                </a>
                            </ul>
                            <h6>Redaktionell Verantwortlicher</h6>
                            <ul className="list-unstyled">
                                <li>Prof. Dr. Hartmut Gülker</li>
                                <li>Ob. Lichtenplatzer Str. 236</li>
                                <li>42287 Wuppertal</li>
                            </ul>
                        </div>
                    </div>
                    <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                    <div className="d-flex flex-column mt-5 mb-5">
                        <h6>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h6>
                        <p>
                            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
                            vor einer Verbraucherschlichtungsstelle teilzunehmen.
                        </p>
                        <h6>Haftung für Inhalte</h6>
                        <ul className="list-unstyled">
                            <li>
                                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
                                auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
                                §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                                Tätigkeit hinweisen.
                            </li>
                            <br />
                            <li>
                                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                                Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                                von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                                umgehend entfernen.
                            </li>
                        </ul>
                        <h6>Haftung für Links</h6>
                        <ul className="list-unstyled">
                            <li>
                                Unser Angebot enthält Links zu externen Websites Dritter, auf deren
                                Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                                fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                                verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                                Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                            </li>
                            <br />
                            <li>
                                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                                ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                                umgehend entfernen.
                            </li>
                        </ul>
                        <h6>Urheberrecht</h6>
                        <ul className="list-unstyled">
                            <li>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                                Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                                Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                                jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
                                sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                            </li>
                            <br />
                            <li>
                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
                                Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
                                eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                                werden wir derartige Inhalte umgehend entfernen.
                            </li>
                        </ul>
                    </div>
                    <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                    <div className="mt-5 d-flex gap-1">
                        <p>Vielen Dank an:{" "}</p>
                        <a href="https://heynhold.de" ref="noopener noreferrer" target="_blank">
                            heynhold.de
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Imprint;