import React, { Component } from 'react';
import PropTypes from 'prop-types';
import File from '../../assets/Stadt Wuppertal übernimmt Verantwortung.pdf';

class Article extends Component {
    render() {
        const { title, date, tag1, tag2, image, sections, overview, showAlert } = this.props;

        return (
            <section className="work">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-8">
                            <article>
                                <div className="mb-4">
                                    <h1 className="fw-bolder mb-1">{title}</h1>
                                    <div className="text-muted fst-italic mb-2">
                                        {date}
                                    </div>

                                    <div className="badge bg-primary text-decoration-none link-light">
                                        {tag1}
                                    </div>

                                    <div className="badge bg-primary text-decoration-none link-light mx-2">
                                        {tag2}
                                    </div>
                                </div>

                                {image && (
                                    <figure className="mb-4">
                                        <img
                                            className="img-fluid rounded"
                                            src={image}
                                            alt="..."
                                        />
                                    </figure>
                                )}

                                <section className="mb-5 text-muted lead">
                                    {sections.map((section, index) => (
                                        <p key={index} className="fs-5 mb-4">
                                            {section}
                                        </p>
                                    ))}
                                </section>
                            </article>
                   
                            {showAlert && (
                                <div className="alert alert-primary" role="alert">
                                    Die Stadt Wuppertal dankt der Herzinitiative und übernimmt das <a href={File} target="_blank" rel="noopener noreferrer" className="alert-link">Projekt</a>.
                                </div>
                            )}
                
                            <a
                                className="btn btn-primary mb-5 text-white"
                                id="button-search"
                                type="button"
                                href={overview}
                            >
                                Zurück zur Übersicht!
                            </a>
                        </div>

                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-header">Jetzt Mitglied werden!</div>
                                <div className="card-body">
                                    <h1 className="font-weight-light">Mitgliedschaft</h1>
                                    <p className="text-muted">Wir freuen uns auf Sie!</p>
                                    <a className="btn btn-primary text-white" href="mitgliedschaft">
                                        Klicken Sie hier!
                                    </a>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-header">Jetzt spenden!</div>
                                <div className="card-body">
                                    <h1 className="font-weight-light">Unterstützung</h1>
                                    <p className="text-muted">Vielen Dank!</p>
                                    <a className="btn btn-primary text-white" href="spenden">
                                        Klicken Sie hier!
                                    </a>
                                </div>
                            </div>

                            <div className="card border-0 bg-body-tertiary mb-5">
                                <div className="card-body p-4 py-lg-5">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="text-center">
                                            <div className="h6 fw-bolder">Weitere Fragen?</div>
                                            <p className="mb-4">
                                                Kontaktieren Sie uns:
                                                <br />
                                                <a
                                                    className="nav-link cb-mail text-primary"
                                                    href="mailto:INFO@WUPPERTALER-HERZINITIATIVE.DE"
                                                >
                                                    info@wuppertaler-herzinitiative.de
                                                </a>
                                            </p>
                                            <div className="h6 fw-bolder">Vielen Dank!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Article.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    tag1: PropTypes.string.isRequired,
    tag2: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
    sections: PropTypes.arrayOf(PropTypes.string).isRequired,
    overview: PropTypes.string.isRequired,
    showAlert: PropTypes.bool
};

export default Article;
