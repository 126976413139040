import React, { Component } from 'react';
import heartbeat from "../../assets/img/heartbeat.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faList, faGlobe, faHeartPulse, faCircleArrowRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';

class AboutInfo extends Component {
    state = {  } 
    render() { 
        return (
            <section className="about-us">
                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Über Uns</h1>
                        </div>
                    </div>
                </section>
                <div className="container lead">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-9">
                            <article className="mb-5">
                                <div className="about-text py-5">
                                    <p className="fs-5 mb-4">
                                        Die Wuppertaler Herzinitiative e.V. wurde von engagierten
                                        Bürgerinnen und Bürgern ins Leben gerufen, um die Versorgung
                                        herzkranker Menschen in Wuppertal zu verbessern. Als eingetragener
                                        Verein verfolgen wir ausschließlich{" "}
                                        <code>gemeinnützige Zwecke</code> und setzen uns engagiert für die Förderung der Herzgesundheit in
                                        Wuppertal und der bergischen Region ein.
                                    </p>
                                </div>
                                <div className="row gx-4 gx-lg-5 align-items-center mb-4 mb-lg-0">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-fluid rounded"
                                            src={heartbeat}
                                            alt="Symbolisierung unserer Hingabe: Ein Herzschlag, der für die leidenschaftliche Mission der Wuppertaler Herzinitiative e.V. steht."
                                        />
                                    </div>
                                    <div className="col-lg-5 call-to-action">
                                        <h1 className="font-weight-light">Mitgliedschaft</h1>
                                        <p className="text-muted">Wir freuen uns auf Sie!</p>
                                        <a className="btn btn-primary text-white" href="mitgliedschaft">
                                            <FontAwesomeIcon icon={faAngleRight} /> Klicken Sie hier!
                                        </a>
                                    </div>
                                </div>
                                <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                                <div className="">
                                    <div className="mt-5 text-center">
                                        <FontAwesomeIcon icon={faTrophy} className="text-muted"/>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                                            <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse text-muted">
                                                Unsere Ziele
                                            </h2>
                                            <hr className="w-50 mx-auto border-dark-subtle" />
                                        </div>
                                    </div>
                                    <p className="fs-5 mb-4">
                                        Unsere Ziele sind klar definiert: Wir möchten das Bewusstsein für
                                        eine verbesserte Herzgesundheit schärfen und insbesondere dem
                                        plötzlichen Herztod, vor allem durch Herzinfarkt, entschlossen
                                        entgegentreten. In Zusammenarbeit mit{" "}
                                        <code>renommierten Kardiologen</code>, die in Kliniken und Praxen
                                        in Wuppertal tätig sind, setzen wir uns für eine optimierte
                                        Versorgung ein.
                                    </p>

                                    <p className="fs-5 mb-4">
                                        <FontAwesomeIcon icon={faCircleArrowRight} className="text-primary" /> Weitere Informationen zu unseren grundlegenden Aufgaben und Zielen:{" "}
                                        <a className="text-decoration-none" href="aufgaben-und-ziele">
                                            KLICKEN SIE HIER
                                        </a>
                                        !
                                    </p>
                                </div>
                                <hr className="m-5 w-100 mx-auto border-dark-subtle" />
                                <div className="">
                                    <div className="mt-5 text-center">
                                        <FontAwesomeIcon icon={faList} className="text-muted" />
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                                            <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse text-muted">
                                                Maßnahmen
                                            </h2>
                                            <hr className="w-50 mx-auto border-dark-subtle" />
                                        </div>
                                    </div>
                                    <p className="fs-5 mb-4">
                                        Die Wuppertaler Herzinitiative engagiert sich aktiv in der
                                        Aufklärung, Laienschulung und der Optimierung der Rettungskette
                                        bei akutem Herzinfarkt. Unser Fokus liegt dabei auf der Stärkung
                                        der gezielten Ersten Hilfe. Wir qualifizieren Laienhelfer,
                                        insbesondere in Herzdruckmassagen und anderen
                                        Reanimationsmaßnahmen, um eine große Zahl von Wuppertalerinnen und
                                        Wuppertalern als "mobile Herzretter im Tal" zu gewinnen.
                                    </p>
                                    <p className="fs-5">
                                        <FontAwesomeIcon icon={faCircleArrowRight} className="text-primary" /> Weitere Details zu unserer Arbeit aus 2024:{" "}
                                        <a className="text-decoration-none" href="aus-unserer-arbeit-neu">
                                            KLICKEN SIE HIER
                                        </a>
                                        !
                                    </p>
                                    <p className="fs-5 mb-4">
                                        <FontAwesomeIcon icon={faCircleArrowRight} className="text-primary" /> Weitere Details zu unserer Arbeit aus den letzten Jahren:{" "}
                                        <a className="text-decoration-none" href="aus-unserer-arbeit-alt">
                                            KLICKEN SIE HIER
                                        </a>
                                        !
                                    </p>

                                </div>
                                <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                                <div className="d-flex flex-column justify-content-center align-items-center m-5">
                                    <div className="lc-block">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            className="text-muted"
                                            width="6em"
                                            height="6em"
                                            viewBox="0 0 24 24"
                                            lc-helper="svg-icon"
                                            fill="currentColor"
                                        >
                                            <path d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" />
                                        </svg>
                                    </div>
                                    <div className="lc-block">
                                        <figure>
                                            <blockquote className="blockquote">
                                                <p editable="inline" className="text-muted text-center">
                                                    "Hilfe im Notfall ist wahrgenommene mitmenschliche Verantwortung."
                                                </p>
                                            </blockquote>
                                            <figcaption className="blockquote-footer text-center">
                                                Klaus H. Richter
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                                <div className="">
                                    <div className="mt-5 text-center">
                                        <FontAwesomeIcon icon={faGlobe} className="text-muted" />
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                                            <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse text-muted">
                                                Zusammenarbeit für die Zukunft
                                            </h2>
                                            <hr className="w-50 mx-auto border-dark-subtle" />
                                        </div>
                                    </div>
                                    <p className="fs-5 mb-4">
                                        Ein weiterer Schwerpunkt unserer Arbeit liegt darin, die weitere
                                        Versorgung von Notfallpatienten dem nächstgelegenen geeigneten
                                        Krankenhaus zu übertragen. Die Zusammenarbeit mit lokalen
                                        Einrichtungen und Organisationen ist für uns von zentraler
                                        Bedeutung, um gemeinsam einen Beitrag zur Herzgesundheit unserer
                                        Gemeinschaft zu leisten.
                                    </p>
                                </div>
                                <hr className="m-5 w-100 mx-auto border-dark-subtle" />
                                <div className="">
                                    <div className="mt-5 text-center">
                                        <FontAwesomeIcon icon={faHeartPulse} className="text-muted" />
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                                            <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse text-muted">
                                                Unterstützen Sie uns
                                            </h2>
                                            <hr className="w-50 mx-auto border-dark-subtle" />
                                        </div>
                                    </div>
                                    <p className="fs-5 mb-4">
                                        Die Wuppertaler Herzinitiative e.V. freut sich darauf, weitere
                                        Mitglieder, Förderer und Sponsoren zu gewinnen, um unsere Ziele
                                        schneller und effektiver erreichen zu können. Gemeinsam setzen wir
                                        uns für eine herzgesunde Zukunft in Wuppertal ein.
                                    </p>
                                    <div className="container d-flex justify-content-center align-items-center text-center">
                                        <div className="alert alert-primary" role="alert">
                                            Helfen Sie mit einer{" "}
                                            <a className="text-decoration-none" href="spenden">
                                                Spende
                                            </a>
                                            !
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutInfo;