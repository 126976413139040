import React, { Component } from 'react';
import MobileRetterImage from "../../assets/img/mobile-retter.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';

class Work extends Component {
    state = {  } 
    render() { 
        return (
            <section className="work">
                <Helmet>
                    <title>Ausschnitte über unsere Arbeit aus den letzten Jahren - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Entdecken Sie Ausschnitte aus der vielfältigen Arbeit und den bedeutenden Projekten der Wuppertaler Herzinitiative e.V. aus den vergangenen Jahren. Erfahren Sie mehr über unsere Bemühungen zur Förderung der Herzgesundheit, zur Unterstützung von Betroffenen und zur Aufklärung der Öffentlichkeit über Herzkrankheiten und deren Prävention." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">
                                Aus unserer Arbeit
                            </h1>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <p className="fw-bold text-muted" style={{ fontSize: '14px' }}>Ausschnitte über unsere Arbeit aus den letzten Jahren:</p>

                    <div className="row">
                        <div className="col-lg-8">

                            <div className="card mb-4">
                                <a href="herzrettung-etabliert">
                                    <img
                                        className="card-img-top"
                                        src={MobileRetterImage}
                                        alt="..."
                                    />
                                </a>
                                <div className="card-body">
                                    <div className="small text-muted">Freitag, 09.02.2024</div>
                                    <h2 className="card-title">Mobile Herzrettung in Wuppertal etabliert</h2>
                                    <p className="card-text">
                                        Erste Hilfe zu verbessern war ein Hauptbeweggrund für die Bildung der Wuppertaler Herzinitiative. Möglichst viele Menschen sollten in die Lage Rücksichtnahmen zu nehmen und die Notwendigkeit versetzt werden, unverzüglich und sachgerecht im Notfall erste Hilfe leisten (...)
                                    </p>
                                    <a className="btn btn-primary text-white" href="herzrettung-etabliert">
                                        Mehr lesen!
                                    </a>
                                </div>
                            </div>

                            <div className="line text-center d-flex align-items-center justify-content-center mb-2" />

                            <div className="d-flex flex-column justify-content-center align-items-center m-5">
                                <div className="lc-block">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        className="text-muted"
                                        width="6em"
                                        height="6em"
                                        viewBox="0 0 24 24"
                                        lc-helper="svg-icon"
                                        fill="currentColor"
                                    >
                                        <path d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" />
                                    </svg>
                                </div>
                                <div className="lc-block">
                                    <figure>
                                        <blockquote className="blockquote">
                                            <p editable="inline" className="text-muted text-center">
                                                "Gesundheit ist nicht alles, aber ohne Gesundheit ist alles nichts."
                                            </p>
                                        </blockquote>
                                        <figcaption className="blockquote-footer text-center">
                                            Arthur Schopenhauer
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="small text-muted">Montag, 29.01.2024</div>
                                    <h2 className="card-title h4">Prävention neue Impulse geben</h2>
                                    <p className="card-text">
                                        Unzweifelhaft ist die Bedeutung von Bewegung, Sport, richtiger Ernährung, Aufgeben des Rauchens u.ä. bekannt. (...)
                                    </p>
                                    <a className="btn btn-primary text-white" href="neue-impulse">
                                        Mehr lesen!
                                    </a>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="small text-muted">Donnerstag, 18.01.2024</div>
                                    <h2 className="card-title h4">Mehr tun für Herzgesundheit</h2>
                                    <p className="card-text">
                                        Mit Herzkrankheiten leben erfordert es tagtäglich Rücksichten zu nehmen und herzgesunde Lebens- und Verhaltensweisen möglichst mitzuführen. (...)
                                    </p>
                                    <a className="btn btn-primary text-white" href="herzgesundheit">
                                        Mehr lesen!
                                    </a>
                                </div>
                            </div>
                            <div className="container d-flex justify-content-center align-items-center text-center mt-3">
                                <div className="alert alert-primary" role="alert">
                                    <FontAwesomeIcon icon={faInfoCircle} /> Neues aus diesem <a href="aus-unserer-arbeit-neu" className="alert-link">Jahr</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Work;