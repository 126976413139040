import React, { Component } from 'react';
import * as texts from './texts';
import Card from './card';
import { Helmet } from 'react-helmet';

import hartmut_guelker_image from '../../assets/img/team/hartmut_guelker.jpg';
import susanne_schoenrock_image from '../../assets/img/team/susanne_schoenrock.jpg';
import ruediger_neumann_image from '../../assets/img/team/ruediger_neumann.jpg';
import josef_achilles_image from '../../assets/img/team/josef_achilles.jpg';
import helge_lindh_image from '../../assets/img/team/helge_lindh.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class BoardOfDirectors extends Component {
    state = {};

    render() {
        const { guelker, schoenrock, neumann, achilles, lindh } = texts.boardOfDirectorsTexts;

        return (
            <section className="team">
                <Helmet>
                    <title>Vorstand - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Informationen über den Vorstand der Wuppertaler Herzinitiative e.V." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">
                                Vorstand
                            </h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="row pt-4 d-flex justify-content-center align-items-center">
                        <div className="row pt-4 d-flex justify-content-center align-items-center">
                            <Card memberData={{ ...guelker, image: hartmut_guelker_image }} />
                            <Card memberData={{ ...neumann, image: ruediger_neumann_image }} />
                            <Card memberData={{ ...schoenrock, image: susanne_schoenrock_image }} />
                            <Card memberData={{ ...achilles, image: josef_achilles_image }} />
                            <Card memberData={{ ...lindh, image: helge_lindh_image }} />
                        </div>
                    </div>
                    <div className="container d-flex justify-content-center align-items-center text-center mt-3">
                        <div className="alert alert-primary" role="alert">
                            <FontAwesomeIcon icon={faInfoCircle} /> Der Vorsitzende des <a href="gremien" className="alert-link">Ärztlichen Beirates</a>, PrivDoz. Dr. Jan Erik Gülker ist qua satzung ebenfalls Vorstandsmitglied.
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BoardOfDirectors;