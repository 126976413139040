import React, { Component } from 'react';
import AboutInfo from "../components/about-us/about-info";
import AboutPosts from "../components/about-us/about-posts";
import { Helmet } from 'react-helmet';

class About extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <Helmet>
                    <title>Über Uns - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über die Wuppertaler Herzinitiative e.V. und unsere Mission, Geschichte, Ziele und das Team, das hinter unserer Organisation steht. Entdecken Sie, wie wir uns für die Förderung der Herzgesundheit einsetzen und wie Sie Teil unserer Bemühungen werden können, das Bewusstsein zu schärfen und Leben zu retten." />
                </Helmet>
                <AboutInfo />
            </React.Fragment>
        );
    }
}
 
export default About;