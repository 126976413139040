import React, { Component } from 'react';
import Image from '../../assets/img/check-list.png';
import StyledList from '../styled-list';
import { Helmet } from 'react-helmet';

class Goals extends Component {
    state = {  } 
    render() { 
        return (
            <section className="goals">
                <Helmet>
                    <title>Ziele - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über die Ziele der Wuppertaler Herzinitiative e.V. und unsere Bemühungen zur Förderung der Herzgesundheit in der Gemeinschaft. Entdecken Sie, wie wir uns für Prävention, Aufklärung, Forschung und Unterstützung von Betroffenen einsetzen, um das Bewusstsein zu schärfen und Leben zu retten." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Aufgaben und Ziele</h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="row mb-4 align-items-center">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="lc-block text-center animate__animated animate__fadeInLeft">
                                <img className="img-fluid" src={Image} alt="Erleben Sie den Mehrwert einer Mitgliedschaft bei der Wuppertaler Herzinitiative e.V.: Exklusive Vorteile und starke Gemeinschaftsbindungen." loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-6 p-lg-6 animate__animated animate__fadeInRight">
                            <div className="lc-block mb-5" editable="rich">
                                <h2 className="display-6 fw-bold">Unsere Aufgaben und Ziele</h2>
                                <p>Aufgaben und Ziele des gemeinnützigen Vereins werden in der SATZUNG geregelt</p>
                            </div>
                            <p>§2 nennt insbesondere</p>
                            
                            <StyledList text="Beiträge zur intensiveren regionalen Aufklärung der Bevölkerung über die erheblichen Gefahren von Herz- und Kreislauferkrankungen und des Herzinfarktes"></StyledList>
                            <StyledList text="Hilfen zur Verkürzung prähospitaler Zeitverluste bei Herzinfarkten"></StyledList>
                            <StyledList text="Förderung der Ersthelferausbildung durch zusätzliche Schulungs- und Informationsangebote (z.B. Herzdruckmassage, AED, Wiederbelebung)"></StyledList>
                            <StyledList text="Fortschreibung der Bemühungen um eine optimierte Infarkttherapie im Bergischen Land .."></StyledList>
                            <StyledList text="Beiträge zu mehr Herzgesundheit durch Bewegung, Sport, Ernährung, Aufgabe des Rauchens"></StyledList>
                            <StyledList text="Zur Unterstützung des Kampfes gegen den plötzlichen Herztod will der Verein ein integrierendes fachliches Herz-Netz ausbauen."></StyledList>
                            
                            <p>Aufgaben und Ziele des Vereins werden durch Beschlüsse der Vereinsgremien (Mitgliederversammlung, Vorstand, Ärztlicher Beirat …) konkretisiert und weiterentwickelt.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Goals;