import React, { Component } from 'react';
import Image from '../../assets/img/donation.png';
import { Helmet } from 'react-helmet';

class DonationContent extends Component {
    state = {  } 
    render() { 
        return (
            <section className="donation">
                <Helmet>
                    <title>Spenden - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Unterstützen Sie die wichtige Arbeit der Wuppertaler Herzinitiative e.V. durch eine Spende. Erfahren Sie, wie Sie spenden können, um unsere Bemühungen zur Förderung der Herzgesundheit und Unterstützung von Betroffenen zu unterstützen. Jede Spende trägt dazu bei, Leben zu retten und die Gemeinschaft zu stärken." />
                </Helmet>


                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Spenden</h1>
                        </div>
                    </div>
                </section>
                <div className="container mt-5">
                    <div className="row mb-4 align-items-center">
                        <div className="col-lg-6 p-lg-6">
                            <div className="lc-block mb-5" editable="rich">
                                <h2 className="display-6 fw-bold">Helfen Sie mit einer Spende.</h2>
                                <p className="lead">Unterstützen Sie die Initiative!</p>
                                <p>
                                    Wir sind von unseren Zielen überzeugt – aber sie müssen auch
                                    finanziert werden. Dazu brauchen wir Unterstützung. Mit Ihrer Spende
                                    tragen Sie zum Beispiel zu einer verbesserten Herzgesundheit und einer
                                    Optimierung der Notfallversorgung bei Herzstillstand u.ä. bei.

                                </p>
                                <p>Vielen Dank!</p>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            className="text-primary"
                                            viewBox="0 0 16 16"
                                            lc-helper="svg-icon"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>100% Sicherheit</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            className="text-primary"
                                            viewBox="0 0 16 16"
                                            lc-helper="svg-icon"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>Gemeinnützige Arbeit fördern</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            className="text-primary"
                                            viewBox="0 0 16 16"
                                            lc-helper="svg-icon"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>Anerkennung und Wertschätzung</p>
                                    </div>
                                </div>
                            </div>
                            <h6 className="fw-bold">
                                Unser Konto: Stadtsparkasse Wuppertal <code>DE81 3305 0000 0000 8293 33</code>
                            </h6>
                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="lc-block text-center">
                                <img
                                    className="img-fluid"
                                    src={Image}
                                    loading="lazy"
                                    alt="Unterstützen Sie die Wuppertaler Herzinitiative e.V. durch eine Spende und tragen Sie zum Erfolg unserer gemeinnützigen Projekte bei."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
 
export default DonationContent;