import React, { Component } from 'react';
import Article from '../article';
import { Helmet } from 'react-helmet';

class Herzgesundheit extends Component {
    state = {}
    render() {
        return (
            <>
                <Helmet>
                    <title>Mehr tun für Herzgesundheit - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Mehr tun für Herzgesundheit', veröffentlicht am Donnerstag, 18.01.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel informiert über die Bemühungen der Initiative, wichtige Hinweise und Ratschläge zur Förderung der Herzgesundheit bereitzustellen. Erfahren Sie, wie die Herzinitiative durch Medienmeldungen und Informationen darauf hinweist, wie wichtig es ist, hohen Blutdruck zu vermeiden, chronisch Kranke an wichtigen Impfungen teilzunehmen und die Konsequenzen aus extremen Wetterbedingungen zu ziehen." />
                </Helmet>

                <Article
                    title={"Mehr tun für Herzgesundheit"}
                    date={"Donnerstag, 18.01.2024"}
                    tag1={"Arbeit"}
                    tag2={"Herzgesundheit"}
                    sections={
                        [
                            "Mit Herzkrankheiten leben erfordert es tagtäglich Rücksichten zu nehmen und herzgesunde Lebens- und Verhaltensweisen möglichst mitzuführen.",
                            "Die Herzinitiative spart daher nicht mit Rat und wichtigen Hinweisen zu mehr Herzgesundheit. So wird durch Meldungen in den Medien auf die unbedingte Notwendigkeit hingearbeitet, hohen Blutdruck unbedingt zu vermeiden.",
                            "Ebenso gilt es immer wieder darüber zu informieren, dass es für chronisch Kranke unabdingbar ist, an wichtigen Impfungen teilzunehmen und die Konsequenzen aus heftigen Wettern des Sommers und Winters zu ziehen. Das gilt auch für die Wahl von Reise-Zeiten und Zielen."
                        ]
                    }
                    overview={"aus-unserer-arbeit-alt"}
                />
            </>
        );
    }
}

export default Herzgesundheit;
