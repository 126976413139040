
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {
    state = {  } 
    render() { 
        return (
            <header className="welcome">
                <div
                    className="container d-flex align-items-center"
                    style={{ height: "100%" }}
                >
                    <div className="row animate__animated animate__pulse">
                        <h1 className="display-1 fw-bold">WILLKOMMEN</h1>
                        <p className="fs-5 fw-bold text-secondary ">
                            auf der Webseite der Wuppertaler Herzinitiative e.V.
                        </p>
                        <div>
                            <a className="btn btn-primary text-white" href="info">
                                <FontAwesomeIcon icon={faInfoCircle} /> Allgemeine Informationen
                            </a>
                        </div>
                        <div>
                            <a className="btn btn-danger text-white mt-2" href="aus-unserer-arbeit-neu">
                                <FontAwesomeIcon icon={faStar} /> Neues aus unserer Arbeit
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
 
export default Header;