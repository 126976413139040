import React, { Component } from 'react';
import Header from '../components/index/header/header';
import IndexContent from '../components/index/content/index-content';
import { Helmet } from 'react-helmet';

class Home extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <Helmet>
                    <title>Startseite - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Willkommen auf der Startseite der Wuppertaler Herzinitiative e.V. Hier erfahren Sie mehr über unsere Mission, unsere aktuellen Veranstaltungen und wie Sie sich engagieren können, um die Herzgesundheit in unserer Gemeinschaft zu fördern. Besuchen Sie uns und entdecken Sie, wie Sie Teil unserer Initiative werden können." />
                </Helmet>
                <Header></Header>
                <IndexContent></IndexContent>
            </React.Fragment>
        );
    }
}
 
export default Home;