import React, { Component } from 'react';
import IMG_1 from '../../assets/img/gruppenfoto_1.jpg';
import IMG_2 from '../../assets/img/gruppenfoto_2.jpg';
import IMG_3 from '../../assets/img/gruppenfoto_3.jpg';
import { Helmet } from 'react-helmet';

class Gallery extends Component {
    state = {  } 
    render() { 
        return (
            <section className="gallery">
                <Helmet>
                    <title>Galerie - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Entdecken Sie die Galerie der Wuppertaler Herzinitiative e.V. und sehen Sie sich inspirierende Bilder und Fotos von unseren Veranstaltungen, Projekten und Mitgliedern an. Tauchen Sie ein in die Welt unserer Aktivitäten und erleben Sie die Herzinitiative hautnah." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Galerie</h1>
                        </div>
                    </div>
                </section>
                <div className="container d-flex justify-content-center align-items-center text-center">
                    <div className="row col-12 mt-4">
                        <figure>
                            <img
                                className="img-fluid rounded"
                                src={IMG_1}
                                alt="..."
                            />
                            <p>Bild 1</p>
                        </figure>
                        <figure>
                            <img
                                className="img-fluid rounded"
                                src={IMG_2}
                                alt="..."
                            />
                            <p>Bild 2</p>
                        </figure>
                        <figure>
                            <img
                                className="img-fluid rounded"
                                src={IMG_3}
                                alt="..."
                            />
                            <p>Bild 3</p>
                        </figure>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Gallery;