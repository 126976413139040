import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

class ContactBar extends Component {
    state = {  } 
    render() { 
        return (
            <nav className="navbar bg-body-tertiary">
                <div className="container cb">
                    <a
                        className="nav-link cb-mail text-secondary mt-1 mt-md-0 mb-2 mb-md-0"
                        href="mailto:INFO@WUPPERTALER-HERZINITIATIVE.DE"
                    >
                        E-Mail: INFO@WUPPERTALER-HERZINITIATIVE.DE
                    </a>
                    <a
                        className="d-flex flex-column justify-content-center"
                        href="https://www.facebook.com/wuppertalerherzinitiativeev"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
            </nav>

        );
    }
}
 
export default ContactBar;