export const boardOfDirectorsTexts = {
    guelker: {
        fade: 'Left',
        name: 'Prof. Dr. Hartmut Gülker',
        role: 'Vorsitzender des Vorstandes',
        bio: 'Der bekannte Kardiologe bringt seine jahrzehntelangen Erfahrungen im Gesundheitswesen und profunden Kenntnisse aus seiner Zeit als Direktor am Herzzentrum am Arrenberg ein.',
    },
    neumann: {
        fade: '',
        name: 'Rüdiger Neumann',
        role: 'Stellv. Vorsitzender des Vorstandes',
        bio: 'Bei seiner neuen ehrenamtlichen Aufgabe kommen Neumann seine langjährigen beruflichen und geschäftlichen Kenntnisse Im Gesundheitswesen und in der Gesundheitswirtschaft zugute. Darüber hinaus hat er sich seit einigen Jahren in der Herzinitiative engagiert.',
    },
    schoenrock: {
        fade: 'Right',
        name: 'Susanne Schönrock',
        role: 'Schatzmeisterin',
        bio: 'Schönrock ist insbesondere für die Finanzen und die wirtschaftlichen Seiten des Vereins zuständig. Als Schatzmeisterin gehört sie dem engeren Vorstand der Herzinitiative an.',
    },
    achilles: {
        fade: 'Left',
        name: 'Josef Achilles',
        role: 'Mitglied im Vorstand',
        bio: 'Ehrenamtlich ist er dem Krankhauswesen - und hier insbesondere der Stiftungsarbeit - eng verbunden.',
    },
    lindh: {
        fade: 'Right',
        name: 'Helge Lindh',
        role: 'Mitglied im Vorstand',
        bio: 'Lindh bringt seine vielfältigen politischen Erfahrungen in die Arbeit der Wuppertaler Herzinitiative e.V. ein, insbesondere durch seine Tätigkeit im Bundestag und seine Beteiligung am politischen Geschehen in seiner bergischen Heimat.',
    },
    richter: {
        fade: 'Left',
        name: 'Klaus H. Richter',
        role: 'Vorstandsbeauftragter',
        bio: 'Richter führt als Vorstandsbeauftragter die Geschäfte des Vorstandes. Er ist einer der Initiatoren der Herzinitiative und war früher Vorstand einer großen Krankenkasse.',
    },
    mueller: {
        fade: 'Right',
        name: 'Michael Müller',
        role: 'Kassenprüfer',
        bio: 'Der bekannte Kommunalpolitiker und Markthändler kennt sich in Wuppertal hervorragend aus. Dieses Wissen bringt er in die Vorstandsarbeit ein.',
    },
    janguelker: {
        fade: 'Left',
        name: 'PrivDoz. Dr. Jan Erik Gülker',
        role: 'Vorsitzender des Ärztlichen Beirates',
        bio: 'Dr. Gülker leitet den Ärztlichen Beirat der Herzinitiative und ist Mitglied des engeren Vorstandes. Er beschäftigt sich vor allem mit neuen medizinischen Entwicklungen und Strukturen in der Kardiologie.',
    },
    berchem: {
        fade: '',
        name: 'Dr. Nina Berchem',
        role: 'Mitglied des Ärztlichen Beirates',
        bio: 'Dr. Berchem ist Fachärztin für innere Medizin. Sie ist in der Leitung des MVZ-Wuppertal II. als Kardiologin tätig.',
    },
    gruenig: {
        fade: 'Right',
        name: 'Dr. Sebastian Grünig',
        role: 'Mitglied des Ärztlichen Beirates',
        bio: 'Dr. Grünig ist in der kardiologischen Praxis im Ärztehaus des St. Josef-Krankenhauses tätig.',
    }
};
