import React, { Component } from 'react';

class Join extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <section className="join">
                    <section className="banner">
                        <div className="container">
                            <div className="text-center">
                                <h1 className="fw-bolder animate__animated animate__pulse">
                                    Jetzt Mitglied werden
                                </h1>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div
                                    className="card border-0 rounded-3 shadow-lg"
                                    style={{ margin: "50px 0px" }}
                                >
                                    <div className="card-body p-4">
                                        <div className="text-center">
                                            <div className="h1 fw-light">Beitrittserklärung</div>
                                            <hr className="w-50 mx-auto border-dark-subtle" />
                                            <p className="mb-4 text-muted">
                                                Ja, ich will ab sofort Mitglied der{" "}
                                                <span className="text-primary">
                                                    Wuppertaler Herzinitiative e.V.
                                                </span>{" "}
                                                werden.
                                            </p>
                                        </div>
                                        <form id="contactForm" action="./join_function.php" method="post">
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="name_firma"
                                                    type="text"
                                                    name="name_firma"
                                                    required=""
                                                />
                                                <label htmlFor="name_firma">
                                                    Name / Firma <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="vorname"
                                                    type="text"
                                                    name="vorname"
                                                    required=""
                                                />
                                                <label htmlFor="vorname">
                                                    Vorname <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="strasse_hausnr"
                                                    type="text"
                                                    name="strasse_hausnr"
                                                    required=""
                                                />
                                                <label htmlFor="strasse_hausnr">
                                                    Straße / Hausnr. <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="plz_wohnort"
                                                    type="text"
                                                    name="plz_wohnort"
                                                    required=""
                                                />
                                                <label htmlFor="plz_wohnort">
                                                    PLZ / Wohnort <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="geburtsdatum"
                                                    type="date"
                                                    name="geburtsdatum"
                                                    required=""
                                                />
                                                <label htmlFor="geburtsdatum">
                                                    Geb.-Datum <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="telefon"
                                                    type="tel"
                                                    name="telefon"
                                                />
                                                <label htmlFor="telefon">Telefon</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="mobil"
                                                    type="tel"
                                                    name="mobil"
                                                    required=""
                                                />
                                                <label htmlFor="mobil">
                                                    Mobil <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    required=""
                                                />
                                                <label htmlFor="email">
                                                    E-Mail <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultValue=""
                                                    id="agbCheckbox"
                                                    required=""
                                                />
                                                <label className="form-check-label" htmlFor="agbCheckbox">
                                                    Ich akzeptiere die{" "}
                                                    <a
                                                        href="#"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#agbModal"
                                                    >
                                                        Einwilligungserklärung
                                                    </a>{" "}
                                                    (EU-Datenschutz-Grundverordnung)
                                                </label>
                                            </div>
                                            <div className="d-grid">
                                                <button
                                                    className="btn btn-primary btn-lg text-white"
                                                    id="submitButton"
                                                    type="submit"
                                                >
                                                    <i className="fa fa-download" /> Ausgefüllte
                                                    Beitrittserklärung herunterladen
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="declaration">
                    <div
                        className="modal fade"
                        id="agbModal"
                        tabIndex={-1}
                        aria-labelledby="agbModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="agbModalLabel">
                                        Einwilligungserklärung (EU-Datenschutz-Grundverordnung){" "}
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <p className="text-muted">
                                        Nach Artikel 13 und 14 EU-DSGVO hat der Verantwortliche einer
                                        betroffenen Person, deren Daten er verarbeitet, in den Artikeln 13
                                        und 14 EU-DSGVO genannten Informationen bereit zu stellen. Dieser
                                        Informationspflicht kommt die Wuppertaler Herzinitiative e.V. mit
                                        diesem Merkblatt nach.
                                        <br />
                                        <br />
                                        Als Mitglied der Wuppertaler Herzinitiative e.V. erkläre ich mich
                                        mit der Erhebung, Bearbeitung (Speicherung, Veränderung,
                                        Übermittlung) oder Nutzung meiner personenbezogenen Daten
                                        einverstanden.
                                        <br />
                                        <br />
                                        Die personenbezogenen Daten werden grundsätzlich im Rahmen des
                                        Erwerbs der Mitgliedschaft erhoben.
                                        <br />
                                        <br />
                                        Die personenbezogenen Daten (Firma, Name und Anschrift,
                                        Bankverbindung, Telefonnummer und Email-Adresse) werden für die
                                        Durchführung des Mitgliedschaftsverhältnisses - auch per EDV-
                                        verarbeitet (z.B. Einladung zu Versammlungen, Beitragseinzug).
                                        Darüber hinaus werden diese Daten im Zusammenhang mit
                                        Vereinsaktivitäten einschließlich der Berichterstattung hierüber
                                        sowie dazu gehöriger Fotos in Printmedien und auf der
                                        Internetseite des Vereins veröffentlicht.
                                        <br />
                                        <br />
                                        Mitgliederlisten werden in digitaler oder gedruckter Form an
                                        Vorstandsmitglieder, sonstige Mitarbeiter und Mitglieder
                                        weitergegeben, wie deren Funktion oder besondere Aufgabenstellung
                                        im Verein die Kenntnisnahme dieser Daten erfordert.
                                        <br />
                                        <br />
                                        Die Verarbeitung der personenbezogenen Daten erfolgt in der Regel
                                        aufgrund der Erforderlichkeit zur Erfüllung des Vertrages gemäß
                                        Artikel 6 Abs. 1 lit. b) DSGVO. Bei den Vertragsverhältnissen
                                        handelt es sich in erster Linie um das Mitgliedschaftsverhältnis
                                        zum Verein. Werden personenbezogene Daten erhoben, ohne dass die
                                        Verarbeitung zur Erfüllung des Vertrages erforderlich ist, erfolgt
                                        die Verarbeitung aufgrund einer Einwilligung nach Artikel 6 Abs. 1
                                        a) i.V. mit Artikel 7 DSGVO. Die Veröffentlichung
                                        personenbezogener Daten im Internet erfolgt zur Wahrung
                                        berechtigter Interessen des Vereins (vgl. Artikel 6 Abs.1 lit f)
                                        DSGVO. Das berechtigte Interesse des Vereins besteht in der
                                        Information der Öffentlichkeit durch Berichterstattung über die
                                        Aktivitäten des Vereins. Im Rahmen der Berichterstattung werden
                                        personenbezogene Daten einschließlich Bildern veröffentlicht. Die
                                        Daten der Bankverbindung der Mitglieder werden zum Zwecke des
                                        Beitragseinzugs an die Stadtsparkasse Wuppertal weitergeleitet.
                                        Die personenbezogenen Daten werden für die Dauer der
                                        Mitgliedschaft gespeichert. Mit der Beendigung der Mitgliedschaft
                                        werden die Datenkategorien gemäß den gesetzlichen
                                        Aufbewahrungsfristen vorgehalten und dann gelöscht. In der Zeit
                                        zwischen Beendigung der Mitgliedschaft und der Löschung wird die
                                        Verarbeitung dieser Daten eingeschränkt. Bestimmte Datenkategorien
                                        werden zum Zweck der Vereinsarchivierung gespeichert. Es handelt
                                        sich um die Kategorie Firma, Inhaber, Gewerbegegenstand, Vorname,
                                        Name. Der Speicherung liegt ein berechtigtes Interesse des Vereins
                                        an der Dokumentation von Vereinsereignissen zugrunde. Alle Daten
                                        der übrigen Kategorien (z.B. Bankdaten, Anschrift, Kontaktdaten)
                                        werden mit Beendigung der Mitgliedschaft gelöscht.
                                        <br />
                                        <br />
                                        Den betroffenen Personen stehen unter den in den jeweils genannten
                                        Voraussetzungen die nachfolgenden Rechte zu:
                                    </p>
                                    <ul>
                                        <li>das Recht auf Auskunft nach Artikel 15 DSGVO</li>
                                        <li>das Recht auf Berichtigung nach Artikel 16 DSGVO</li>
                                        <li>das Recht auf Löschung nach Artikel 17 DSGVO</li>
                                        <li>das Recht auf Einschränkung nach Artikel 18 DSGVO</li>
                                        <li>das Recht auf Datenübertragbarkeit nach Artikel 20 DSGVO</li>
                                        <li>das Widerspruchsrecht nach Artikel 21 DSGVO</li>
                                        <li>
                                            das Recht auf Beschwerde bei einer Aufsichtsbehörde nach Artikel
                                            77 DSGVO
                                        </li>
                                        <li>
                                            das Recht, eine erteilte Einwilligung jederzeit widerrufen zu
                                            können, ohne dass die Rechtmäßigkeit der aufgrund der
                                            Einwilligung
                                        </li>
                                    </ul>
                                    bis zum Widerruf erfolgten Verarbeitung hierdurch berührt wird
                                    <br />
                                    <br />
                                    Auszug aus der Satzung des Vereins: Der Verein führt den Namen:
                                    Wuppertaler Herzinitiative e.V. Der Verein verfolgt ausschließlich
                                    und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts
                                    „Steuerbegünstigte Zwecke“ der Abgabenordnung. Dabei handelt es sich
                                    in erster Linie um die Förderung des öffentlichen Gesundheitswesens
                                    und der öffentlichen Gesundheitspflege. Schwerpunkt ist insoweit die
                                    Bekämpfung von Herz- und Kreislauferkrankungen unter anderem durch
                                    die Verbesserung des Wissens der Bevölkerung über diese Krankheiten
                                    und die Förderung der Herzgesundheit im Bergischen Land. Ergänzend
                                    sollen Beiträge geleistet werden, die insbesondere der
                                    Wissensverbesserung dienen, wann und wie Bürgerinnen und Bürger zur
                                    Vermeidung des plötzlichen Herztodes beitragen können. Der Verein
                                    ist selbstlos tätig; er verfolgt nicht in erster Linie
                                    eigenwirtschaftliche Zwecke. Die vollständige Satzung kann beim
                                    Vereinsvorstand angefordert werden.
                                    <p />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
 
export default Join;