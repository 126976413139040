import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Article from '../article';

class NeueImpulse extends Component {
    state = {}
    render() {
        return (
            <>
                <Helmet>
                    <title>Prävention neue Impulse geben - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Prävention neue Impulse geben', veröffentlicht am Montag, 29.01.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel beleuchtet die Bedeutung von Bewegung, Sport, richtiger Ernährung und anderen präventiven Maßnahmen für die Herzgesundheit. Erfahren Sie, wie die Herzinitiative neue Impulse setzt und Konzepte für die Region entwickelt, um mit neuen eigenen Angeboten zur Prävention aufzuwarten." />
                </Helmet>
                <Article
                    title={"Prävention neue Impulse geben"}
                    date={"Montag, 29.01.2024"}
                    tag1={"Arbeit"}
                    tag2={"Prävention"}
                    sections={
                        [
                            "Unzweifelhaft ist die Bedeutung von Bewegung, Sport, richtiger Ernährung, Aufgeben des Rauchens u.ä. bekannt. Trotzdem ist es erforderlich, darauf immer wieder gezielt hinzuweisen. Deshalb bezieht die Herzinitiative diese Thematik oft in Vorträge und Informationen ein.",
                            "Derzeit ist der Verein dabei, Konzepte für die Region zu entwickeln, um mit neuen eigenen Angeboten zur Prävention aufwarten zu können."
                        ]
                    }
                    overview={"aus-unserer-arbeit-alt"}
                />
            </>
        );
    }
}

export default NeueImpulse;
