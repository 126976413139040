import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DataProtection extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <section className="part-1">
                    <Helmet>
                        <title>Datenschutz - Wuppertaler Herzinitiative e.V.</title>
                        <meta name="description" content="Erfahren Sie mehr über den Datenschutz bei der Wuppertaler Herzinitiative e.V. Lesen Sie unsere Datenschutzerklärung, um zu verstehen, wie wir Ihre persönlichen Daten erfassen, verwenden, schützen und verarbeiten. Ihre Privatsphäre ist uns wichtig, und wir sind bestrebt, Ihre Daten sicher und vertraulich zu behandeln." />
                    </Helmet>

                    <section className="banner">
                        <div className="container">
                            <div className="text-center">
                                <h1 className="fw-bolder animate__animated animate__pulse">
                                    Datenschutz
                                </h1>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="d-flex flex-column my-5">
                            <div className="mb-5">
                                <h5>1. Datenschutz auf einen Blick</h5>
                                <h6>Allgemeine Hinweise</h6>
                                <p>
                                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was
                                    mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
                                    besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                                    persönlich identifiziert werden können. Ausführliche Informationen
                                    zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                                    aufgeführten Datenschutzerklärung.
                                </p>
                                <h6>Datenerfassung auf dieser Website</h6>
                                <h6>
                                    Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                                </h6>
                                <p>
                                    Die Datenverarbeitung auf dieser Website erfolgt durch den
                                    Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
                                    „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
                                    entnehmen.
                                </p>
                                <h6>Wie erfassen wir Ihre Daten?</h6>
                                <p>
                                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                                    mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
                                    ein Kontaktformular eingeben.
                                </p>
                                <p>
                                    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
                                    Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                                    allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
                                    Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                                    automatisch, sobald Sie diese Website betreten.
                                </p>
                                <h6>Wofür nutzen wir Ihre Daten?</h6>
                                <p>
                                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
                                    der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                                    Nutzerverhaltens verwendet werden.
                                </p>
                                <h6>Welche Rechte haben Sie bezüglich Ihrer Daten?</h6>
                                <p>
                                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
                                    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                                    erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                                    Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
                                    Datenverarbeitung erteilt haben, können Sie diese Einwilligung
                                    jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                                    unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                                    personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                                    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                                </p>
                                <p>
                                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                                    sich jederzeit an uns wenden.
                                </p>
                            </div>
                        </div>
                        <div className="line text-center d-flex align-items-center justify-content-center" />
                    </div>
                </section>
                <section className="part-2 bg-body-tertiary">
                    <div className="container">
                        <div className="d-flex flex-column">
                            <div className="mt-5 mb-5">
                                <h5>2. Hosting und Content Delivery Networks (CDN)</h5>
                                <h6>Externes Hosting</h6>
                                <p>
                                    Diese Website wird bei einem externen Dienstleister gehostet
                                    (Hoster). Die personenbezogenen Daten, die auf dieser Website
                                    erfasst werden, werden auf den Servern des Hosters gespeichert.
                                    Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
                                    und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                                    Websitezugriffe und sonstige Daten, die über eine Website generiert
                                    werden, handeln.
                                </p>
                                <p>
                                    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
                                    gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
                                    lit. b DSGVO) und im Interesse einer sicheren, schnellen und
                                    effizienten Bereitstellung unseres Online-Angebots durch einen
                                    professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                                </p>
                                <p>
                                    Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
                                    Erfüllung seiner Leistungspflichten erforderlich ist und unsere
                                    Weisungen in Bezug auf diese Daten befolgen.
                                </p>
                                <ul className="list-unstyled">
                                    <li className="h6">Wir setzen folgenden Hoster ein:</li>
                                    <li>1&amp;1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur</li>
                                </ul>
                                <h6>Abschluss eines Vertrages über Auftragsverarbeitung</h6>
                                <p>
                                    Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
                                    einen Vertrag über Auftragsverarbeitung mit unserem Hoster
                                    geschlossen.
                                </p>
                            </div>
                        </div>
                        <div className="line text-center d-flex align-items-center justify-content-center" />
                    </div>
                </section>
                <section className="part-3">
                    <div className="container">
                        <div className="d-flex flex-column">
                            <div className="mt-5 mb-5">
                                <h5>3. Allgemeine Hinweise und Pflicht­informationen Datenschutz</h5>
                                <p>
                                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                                    Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                                    vertraulich und entsprechend der gesetzlichen
                                    Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                                </p>
                                <p>
                                    Wenn Sie diese Website benutzen, werden verschiedene
                                    personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
                                    mit denen Sie persönlich identifiziert werden können. Die
                                    vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
                                    und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
                                    Zweck das geschieht.
                                </p>
                                <p>
                                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
                                    bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                                    Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                                    nicht möglich.
                                </p>
                                <h6>Hinweis zur verantwortlichen Stelle</h6>
                                <p>
                                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                                    Website ist:
                                </p>
                                <ul className="list-unstyled">
                                    <li>Klaus H. Richter</li>
                                    <li>Briller Höhe 8</li>
                                    <li>42115 Wuppertal</li>
                                </ul>
                                <ul className="list-unstyled">
                                    <li>Telefon: +49 (0) 202 45 95 899</li>
                                    <li>E-Mail: info@wuppertaler-herzinitiative.de</li>
                                </ul>
                                <p>
                                    Verantwortliche Stelle ist die natürliche oder juristische Person,
                                    die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                                    Verarbeitung von personenbezogenen Daten (z. B. Namen,
                                    E-Mail-Adressen o. Ä.) entscheidet.
                                </p>
                                <h6>Speicherdauer</h6>
                                <p>
                                    Soweit innerhalb dieser Datenschutzerklärung keine speziellere
                                    Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
                                    bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
                                    ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
                                    zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
                                    wir keinen anderen rechtlich zulässigen Gründe für die Speicherung
                                    Ihrer personenbezogenen Daten haben (z.B. steuer- oder
                                    handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
                                    erfolgt die Löschung nach Fortfall dieser Gründe.
                                </p>
                                <h6>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h6>
                                <p>
                                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                                    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                                    jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
                                    erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                                </p>
                                <h6>
                                    Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
                                    gegen Direktwerbung (Art. 21 DSGVO)
                                </h6>
                                <p>
                                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
                                    ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
                                    DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
                                    VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
                                    DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
                                    DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                                    ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                                    EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
                                    MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
                                    GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
                                    UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                                    GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                                    (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                                </p>
                                <p>
                                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                                    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
                                    VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                                    DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
                                    SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                                    WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                                    NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
                                    ART. 21 ABS. 2 DSGVO).
                                </p>
                                <h6>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h6>
                                <p>
                                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                                    Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                                    Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                                    oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
                                    besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                                    gerichtlicher Rechtsbehelfe.
                                </p>
                                <h6>Recht auf Daten­übertrag­barkeit</h6>
                                <p>
                                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
                                    oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
                                    oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                                    aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
                                    an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                                    soweit es technisch machbar ist.
                                </p>
                                <h6>SSL- bzw. TLS-Verschlüsselung</h6>
                                <p>
                                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                                    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                                    oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
                                    bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
                                    Sie daran, dass die Adresszeile des Browsers von „http://“ auf
                                    „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                                </p>
                                <p>
                                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                                    Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                                    werden.
                                </p>
                                <h6>Auskunft, Löschung und Berichtigung</h6>
                                <p>
                                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                                    jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                                    gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
                                    und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                                    Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
                                    Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
                                    uns wenden.
                                </p>
                                <h6>Recht auf Einschränkung der Verarbeitung</h6>
                                <p>
                                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                                    personenbezogenen Daten zu verlangen. Hierzu können Sie sich
                                    jederzeit an uns wenden. Das Recht auf Einschränkung der
                                    Verarbeitung besteht in folgenden Fällen:
                                </p>
                                <ul>
                                    <li>
                                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                                        personenbezogenen Daten bestreiten, benötigen wir in der Regel
                                        Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                                        das Recht, die Einschränkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen.
                                    </li>
                                    <li>
                                        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                                        geschah/geschieht, können Sie statt der Löschung die Einschränkung
                                        der Datenverarbeitung verlangen.
                                    </li>
                                    <li>
                                        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                                        sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                                        Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                                        Löschung die Einschränkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen.
                                    </li>
                                    <li>
                                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                                        haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                                        vorgenommen werden. Solange noch nicht feststeht, wessen
                                        Interessen überwiegen, haben Sie das Recht, die Einschränkung der
                                        Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                    </li>
                                </ul>
                                <p>
                                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                                    eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
                                    abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                                    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
                                    Rechte einer anderen natürlichen oder juristischen Person oder aus
                                    Gründen eines wichtigen öffentlichen Interesses der Europäischen
                                    Union oder eines Mitgliedstaats verarbeitet werden.
                                </p>
                                <h6>Widerspruch gegen Werbe-E-Mails</h6>
                                <p>
                                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                                    Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
                                    Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                                    Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
                                    im Falle der unverlangten Zusendung von Werbeinformationen, etwa
                                    durch Spam-E-Mails, vor.
                                </p>
                            </div>
                        </div>
                        <div className="line text-center d-flex align-items-center justify-content-center" />
                    </div>
                </section>
                <section className="part-4 bg-body-tertiary">
                    <div className="container">
                        <div className="d-flex flex-column">
                            <div className="mt-5 mb-5">
                                <h5>4. Datenerfassung auf dieser Website</h5>
                                <h6>Server-Log-Dateien</h6>
                                <p>
                                    Der Provider der Seiten erhebt und speichert automatisch
                                    Informationen in so genannten Server-Log-Dateien, die Ihr Browser
                                    automatisch an uns übermittelt. Dies sind:
                                </p>
                                <ul>
                                    <li>Browsertyp und Browserversion</li>
                                    <li>verwendetes Betriebssystem</li>
                                    <li>Referrer URL</li>
                                    <li>Hostname des zugreifenden Rechners</li>
                                    <li>Uhrzeit der Serveranfrage</li>
                                    <li>IP-Adresse</li>
                                </ul>
                                <p>
                                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                                    nicht vorgenommen.
                                </p>
                                <p>
                                    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
                                    lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
                                    der technisch fehlerfreien Darstellung und der Optimierung seiner
                                    Website – hierzu müssen die Server-Log-Files erfasst werden
                                </p>
                                <h6>Anfrage per E-Mail, Telefon oder Telefax</h6>
                                <p>
                                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                                    Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
                                    Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
                                    uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
                                    Ihre Einwilligung weiter.
                                </p>
                                <p>
                                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                                    1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
                                    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                                    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
                                    auf unserem berechtigten Interesse an der effektiven Bearbeitung der
                                    an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
                                    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                                    abgefragt wurde.
                                </p>
                                <p>
                                    Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                                    verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                                    Einwilligung zur Speicherung widerrufen oder der Zweck für die
                                    Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
                                    Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
                                    gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                                </p>
                            </div>
                        </div>
                        <div className="line text-center d-flex align-items-center justify-content-center" />
                    </div>
                </section>
                <section className="part-5">
                    <div className="container">
                        <div className="d-flex flex-column">
                            <div className="mt-5 mb-5">
                                <h5>Unsere Social–Media–Auftritte</h5>
                                <h6>Datenverarbeitung durch soziale Netzwerke</h6>
                                <p>
                                    Wir unterhalten öffentlich zugängliche Profile in sozialen
                                    Netzwerken. Die im Einzelnen von uns genutzten sozialen Netzwerke
                                    finden Sie weiter unten.
                                </p>
                                <p>
                                    Soziale Netzwerke wie Facebook, Twitter etc. können Ihr
                                    Nutzerverhalten in der Regel umfassend analysieren, wenn Sie deren
                                    Website oder eine Website mit integrierten Social-Media-Inhalten (z.
                                    B. Like-Buttons oder Werbebannern) besuchen. Durch den Besuch
                                    unserer Social-Media-Präsenzen werden zahlreiche
                                    datenschutzrelevante Verarbeitungsvorgänge ausgelöst. Im Einzelnen:
                                </p>
                                <p>
                                    Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere
                                    Social-Media-Präsenz besuchen, kann der Betreiber des
                                    Social-Media-Portals diesen Besuch Ihrem Benutzerkonto zuordnen.
                                    Ihre personenbezogenen Daten können unter Umständen aber auch dann
                                    erfasst werden, wenn Sie nicht eingeloggt sind oder keinen Account
                                    beim jeweiligen Social-Media-Portal besitzen. Diese Datenerfassung
                                    erfolgt in diesem Fall beispielsweise über Cookies, die auf Ihrem
                                    Endgerät gespeichert werden oder durch Erfassung Ihrer IP-Adresse.
                                </p>
                                <p>
                                    Mit Hilfe der so erfassten Daten können die Betreiber der
                                    Social-Media-Portale Nutzerprofile erstellen, in denen Ihre
                                    Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann
                                    Ihnen interessenbezogene Werbung in- und außerhalb der jeweiligen
                                    Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account
                                    beim jeweiligen sozialen Netzwerk verfügen, kann die
                                    interessenbezogene Werbung auf allen Geräten angezeigt werden, auf
                                    denen Sie eingeloggt sind oder eingeloggt waren.
                                </p>
                                <p>
                                    Bitte beachten Sie außerdem, dass wir nicht alle
                                    Verarbeitungsprozesse auf den Social-Media-Portalen nachvollziehen
                                    können. Je nach Anbieter können daher ggf. weitere
                                    Verarbeitungsvorgänge von den Betreibern der Social-Media-Portale
                                    durchgeführt werden. Details hierzu entnehmen Sie den
                                    Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen
                                    Social-Media-Portale.
                                </p>
                                <h6>Rechtsgrundlage</h6>
                                <p>
                                    Unsere Social-Media-Auftritte sollen eine möglichst umfassende
                                    Präsenz im Internet gewährleisten. Hierbei handelt es sich um ein
                                    berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO. Die
                                    von den sozialen Netzwerken initiierten Analyseprozesse beruhen ggf.
                                    auf abweichenden Rechtsgrundlagen, die von den Betreibern der
                                    sozialen Netzwerke anzugeben sind (z. B. Einwilligung im Sinne des
                                    Art. 6 Abs. 1 lit. a DSGVO).
                                </p>
                                <h6>Verantwortlicher und Geltendmachung von Rechten</h6>
                                <p>
                                    Wenn Sie einen unserer Social-Media-Auftritte (z. B. Facebook)
                                    besuchen, sind wir gemeinsam mit dem Betreiber der
                                    Social-Media-Plattform für die bei diesem Besuch ausgelösten
                                    Datenverarbeitungsvorgänge verantwortlich. Sie können Ihre Rechte
                                    (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung,
                                    Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl ggü. uns
                                    als auch ggü. dem Betreiber des jeweiligen Social-Media-Portals (z.
                                    B. ggü. Facebook) geltend machen.
                                </p>
                                <p>
                                    Bitte beachten Sie, dass wir trotz der gemeinsamen
                                    Verantwortlichkeit mit den Social-Media-Portal-Betreibern nicht
                                    vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der
                                    Social-Media-Portale haben. Unsere Möglichkeiten richten sich
                                    maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters.
                                </p>
                                <h6>Speicherdauer</h6>
                                <p>
                                    Die unmittelbar von uns über die Social-Media-Präsenz erfassten
                                    Daten werden von unseren Systemen gelöscht, sobald der Zweck für
                                    ihre Speicherung entfällt, Sie uns zur Löschung auffordern, Ihre
                                    Einwilligung zur Speicherung widerrufen oder der Zweck für die
                                    Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem
                                    Endgerät, bis Sie sie löschen. Zwingende gesetzliche Bestimmungen –
                                    insb. Aufbewahrungsfristen – bleiben unberührt.
                                </p>
                                <p>
                                    Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
                                    sozialen Netzwerke zu eigenen Zwecken gespeichert werden, haben wir
                                    keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
                                    direkt bei den Betreibern der sozialen Netzwerke (z. B. in deren
                                    Datenschutzerklärung, siehe unten).
                                </p>
                            </div>
                        </div>
                        <div className="line text-center d-flex align-items-center justify-content-center" />
                    </div>
                </section>
                <section className="part-6 bg-body-tertiary">
                    <div className="container">
                        <div className="d-flex flex-column">
                            <div className="mt-5 mb-5">
                                <h5>Soziale Netzwerke</h5>
                                <h6>Facebook</h6>
                                <p>
                                    Wir verfügen über ein Profil bei Facebook. Anbieter dieses Dienstes
                                    ist die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal
                                    Harbour, Dublin 2, Irland. Die erfassten Daten werden nach Aussage
                                    von Facebook auch in die USA und in andere Drittländer übertragen
                                </p>
                                <p>
                                    Wir haben mit Facebook eine Vereinbarung über gemeinsame
                                    Verarbeitung (Controller Addendum) geschlossen. In dieser
                                    Vereinbarung wird festgelegt, für welche Datenverarbeitungsvorgänge
                                    wir bzw. Facebook verantwortlich ist, wenn Sie unsere Facebook-Page
                                    besuchen. Diese Vereinbarung können Sie unter folgendem Link
                                    einsehen:{" "}
                                    <a
                                        href="https://www.facebook.com/legal/terms/page_controller_addendum"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link
                                    </a>
                                    .
                                </p>
                                <ul className="list-unstyled">
                                    <li>
                                        Sie können Ihre Werbeeinstellungen selbstständig in Ihrem
                                        Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden Link und
                                        loggen Sie sich ein:
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/settings?tab=adsa"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Link
                                        </a>
                                        .
                                    </li>
                                </ul>
                                <p>
                                    Die Datenübertragung in die USA wird auf die
                                    Standardvertragsklauseln der EU-Kommission gestützt. Details finden
                                    Sie hier:{" "}
                                    <a
                                        href="https://www.facebook.com/legal/EU_data_transfer_addendum und
                                        https://de-de.facebook.com/help/566994660333381"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link
                                    </a>
                                    .
                                </p>
                                <p>
                                    Details entnehmen Sie der Datenschutzerklärung von Facebook:{" "}
                                    <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer">
                                        Link
                                    </a>
                                    .
                                </p>
                                
                                <h6>Instagram</h6>
                                <p>Vergleichbare Regelungen gelten für Instagram.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
 
export default DataProtection;