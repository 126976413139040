import React, { Component } from 'react';
import Article from '../article';
import { Helmet } from 'react-helmet';

class HerzenRetten extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Helmet>
                    <title>Wir helfen Herzen retten - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Wir helfen Herzen retten', veröffentlicht am Freitag, 12.04.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel informiert über die Bemühungen der Initiative, das Smartphone-basierte Mobile Retter-Programm nach Wuppertal zu bringen, zu organisieren und zu finanzieren. Erfahren Sie, wie bis Ende 2023 fast 500 qualifizierte Ersthelfer in Wuppertal gewonnen, geschult und eingewiesen wurden und wie viele Rettungseinsätze erfolgreich begleitet wurden. Lesen Sie, wie die Stadt Wuppertal nun die Federführung des Projektes übernommen hat und wie die Herzinitiative sich weiterhin engagiert, um das Projekt auszubauen und mehr Herzretterinnen und Herzretter zu gewinnen." />
                </Helmet>

                <Article
                    title={"Wir helfen Herzen retten"}
                    date={"Freitag, 12.04.2024"}
                    tag1={"Arbeit"}
                    tag2={"Erste Hilfe"}
                    sections={
                        [
                            "2022 haben wir das Smartphone-basierte Mobile Retter-Programm nach Wuppertal geholt, es organisiert und finanziert. Inzwischen ist es nicht allein in Wuppertal etabliert – auch Solingen hat Mobile Retter und Remscheid schafft gerade die Voraussetzungen.",
                            "Bis Ende 2023 wurden fast 500 qualifizierte Ersthelfer in Wuppertal gewonnen, geschult und eingewiesen. Mehr als ….. Rettungseinsätze wurden erbracht bzw. begleitet – erfolgreich, zumeist in enger Zusammenarbeit mit dem professionellen Rettungsdienst.",
                            "Nunmehr hat die Stadt Wuppertal die Federführung des Projektes übernommen. Natürlich bleibt die Herzinitiative dem Projekt eng und überzeugt verbunden.  Wir werden intensiv für einen weiteren Ausbau und noch mehr Herzretterinnen und Herzretter eintreten und öffentlich werben. Die aktive Beteiligung an Veranstaltungen (z.B. Blaulicht-Meile) ist geplant.",
                            "Erste Hilfe bleibt eine Leitfunktion der Arbeit der Herzinitiative!"
                        ]
                    }
                    overview={"aus-unserer-arbeit-neu"}
                />
            </> 
        );
    }
}
 
export default HerzenRetten;