import React, { Component } from 'react';
import logo from "../../assets/img/logo_2.png";
import StickyNavbar from './sticky-navbar';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLink: window.location.pathname,
            isMenuOpen: false
        };
    }

    componentDidMount() {
        const stickyNavbar = new StickyNavbar();
        stickyNavbar.init();

        this.cleanup = () => {
            window.removeEventListener('scroll', stickyNavbar.checkScrollPosition);
        };
    }

    componentWillUnmount() {
        if (this.cleanup) {
            this.cleanup();
        }
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <a href="/" className="navbar-brand">
                        <img src={logo} alt="Wuppertaler Herzinitiative e.V." width="30" height="24" />
                    </a>

                    <button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`collapse navbar-collapse justify-content-end ${this.state.isMenuOpen ? 'show' : ''}`}>
                        <div className="navbar-nav">
                            <a href="/info" className={`nav-link ${this.state.activeLink === '/info' ? 'active' : ''}`}>ÜBER UNS</a>
                            <a href="/vorstand" className={`nav-link ${this.state.activeLink === '/vorstand' ? 'active' : ''}`}>VORSTAND</a>
                            <a href="/gremien" className={`nav-link ${this.state.activeLink === '/gremien' ? 'active' : ''}`}>GREMIEN</a>
                            <a href="/mitgliedschaft" className={`nav-link ${this.state.activeLink === '/mitgliedschaft' ? 'active' : ''}`}>MITGLIEDSCHAFT</a>
                            <a href="/spenden" className={`nav-link ${this.state.activeLink === '/spenden' ? 'active' : ''}`}>SPENDEN</a>
                            <a href="/faq" className={`nav-link ${this.state.activeLink === '/faq' ? 'active' : ''}`}>FAQ</a>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;
