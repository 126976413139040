class StickyNavbar {
    constructor() {
        this.firstNavbar = document.querySelector('.navbar');
        this.secondNavbar = document.querySelector('.navbar-expand-lg');
        this.originalFirstNavbarHeight = this.firstNavbar.offsetHeight;
        this.isFixed = false;

        this.checkScrollPosition = this.checkScrollPosition.bind(this);
    }

    init() {
        this.checkScrollPosition();
        window.addEventListener('scroll', this.checkScrollPosition);
    }

    checkScrollPosition() {
        const bannerElement = document.querySelector('.banner');
        const scrollPosition = window.scrollY;

        if (bannerElement) {
            if (scrollPosition > this.originalFirstNavbarHeight) {
                if (!this.isFixed) {
                    bannerElement.classList.add('position-sticky');
                    bannerElement.style.marginBottom = '80px';
                    this.secondNavbar.classList.add('fixed-top');
                    this.isFixed = true;
                }
            } else {
                if (this.isFixed) {
                    bannerElement.classList.remove('position-sticky');
                    bannerElement.style.marginBottom = '0px';
                    this.secondNavbar.classList.remove('fixed-top');
                    this.isFixed = false;
                }
            }
        } else {
            if (scrollPosition > this.originalFirstNavbarHeight) {
                if (!this.isFixed) {
                    this.secondNavbar.classList.add('fixed-top');
                    this.isFixed = true;
                }
            } else {
                if (this.isFixed) {
                    this.secondNavbar.classList.remove('fixed-top');
                    this.isFixed = false;
                }
            }
        }
    }


}

export default StickyNavbar;
