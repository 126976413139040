import React, { Component } from 'react';
import Article from '../article';
import { Helmet } from 'react-helmet';

class Herzsachen extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Helmet>
                    <title>Wir navigieren für Ihre Herzgesundheit und Ihre gesundheitliche Versorgung - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Wir navigieren für Ihre Herzgesundheit und Ihre gesundheitliche Versorgung', veröffentlicht am Dienstag, 16.04.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel beleuchtet die Bemühungen der Herzinitiative, ein Wegweiser und Ratgeber rund um das Herz zu werden. Erfahren Sie, wie die Initiative plant, Mitgliedern und Freunden kurzfristig Orientierung und Unterstützung anzubieten. Die geplanten Maßnahmen umfassen die Neugestaltung der Informationsarbeit, Kooperationen mit der Deutschen Herzstiftung, verstärkte Präsenz in sozialen Medien und intensivierte Veranstaltungsarbeit. Trotz der verstärkten Informationsdichte wird betont, dass die Herzinitiative keine individuelle Beratung im Behandlungsfall vornehmen wird." />
                </Helmet>

                <Article
                    title={"Wir navigieren für Ihre Herzgesundheit und Ihre gesundheitliche Versorgung"}
                    date={"Dienstag, 16.04.2024"}
                    tag1={"Arbeit"}
                    tag2={"Herzgesundheit"}
                    sections={
                        [
                            "Die Wuppertaler Herzinitiative e.V. setzt sich intensiv für die Herzgesundheit der Frauen und Männer in der Region ein. Wir navigieren für Sie und wollen Wegweiser und  Ratgeber rund um das Herz werden. „Das werden wir Mitgliedern  und Freunden  der Herzinitiative kurzfristig anbieten“ So Prof. Dr. Hartmut Gülker, der Vorstandsvorsitzende,  bei seinen Einblicke in die aktuellen Entwicklungen und geplanten Aktivitäten des Vereins.",
                            "Täglich suchen Menschen in Wuppertal nach wichtigen Informationen im Bereich der Kardiologie. Vermehrt auch bei den Verantwortlichen der Herzinitiative . Die Fragen reichen von spezialisierten Krankenhäusern und Ärzten bis zu den Besonderheiten der Herzgesundheit bei Frauen. Die Herzinitiative plant daher eine weitreichende Neugestaltung ihrer Informationsarbeit, um Antworten auf diese Fragen zu liefern.",
                            "Die geplanten Maßnahmen umfassen die regelmäßige Herausgabe des Newsletters 'Herzenssachen', die kompetente Beantwortung von Fragen und Bereitstellung von Informationen des durch den Vorstandsvorsitzenden (ggf. später auch durch Ärztliche Beiräte des Vereins) über das Mobil-Telefon zu festen Terminen. Zudem wird die Herzinitiative verstärkt auf Facebook und Instagram präsent sein; trotzdem bleiben die traditionellen Medien eingebunden.",
                            "Eine Kooperation mit der Deutschen Herzstiftung auf lokaler und regionaler Ebene ist ebenfalls Teil der Planungen.",
                            "Trotz der verstärkten Informationsdichte ist es wichtig zu betonen, dass die Herzinitiative keine individuelle Beratung im Behandlungsfall vornehmen wird. Das ist nicht geplant.",
                            "Zu den Anstrengungen  um eine dichtere qualifizierte Information und Beantwortung von Patientenfragen gehört auch unsere Veranstaltungsarbeit: Auch diese wird intensiviert.Insbesondere Ärztinnen und Ärzte  werden in speziellen öffentlichen Veranstaltungen wichtige Themen der Herz - und Kreislaufmedizin vorstellen.Geplante Aktivitäten fokussieren sich im Moment auf den plötzlichen Herztod, die Unterschiede im Frauenherz und die Herausforderungen bei Diabetes und Herzerkrankungen.",
                            "Großen Raum werden in der Öffentlichkeits- und Veranstaltungsarbeit der Herzinitiative auch in Zukunft Prävention und Gesundheitsförderung einnehmen. Unsere Aktivitäten in diesem Bereich werden vollständig überarbeitet. Wir suchen nach qualifizierten Kooperationen und aktiven Mitstreitern. Gerade in der Herz- und Kreislauf-Medizin muss Prävention, Gesundheitsförderung und eine entsprechende Lebensführung noch größere Bedeutung erhalten. Wir melden uns.",
                            "Die gesammelten Vorhaben unter dem Titel 'Herzenssachen' sollen maßgeblich dazu beitragen,  die Ziele der Wuppertaler Herzinitiative zur Verbesserung der Herzgesundheit in Wuppertal zu erreichen. Die Initiative bleibt weiterhin stark engagiert, insbesondere in den Bereichen Herzrettung, Prävention und Gesundheitsförderung."

                        ]
                    }
                    overview={"aus-unserer-arbeit-neu"}
                />
            </>
        );
    }
}
 
export default Herzsachen;