import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <footer className="bg-body-tertiary">
                <div className="container">
                    {/* 
                    <div className="row donator mb-4">
                        <div className="col-4" />
                        <div className="col-4" />
                        <div className="col-4" />
                    </div>
                    */}
                    <div className="d-flex justify-content-center mb-4 mt-5">
                        <a
                            className="d-flex flex-column justify-content-center mr-3"
                            href="https://www.facebook.com/wuppertalerherzinitiativeev"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <div style={{ width: '20px' }}></div> {/* Hier wird der Abstand eingefügt */}
                        <a
                            className="d-flex flex-column justify-content-center ml-3"
                            href="https://www.instagram.com/wuppertaler.herzinitiative"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>

                    <a
                        className="d-flex flex-column justify-content-center"
                        href="https://www.facebook.com/wuppertalerherzinitiativeev"
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>
                    <div className="text-center mb-4">
                        <a
                            className="d-flex flex-column justify-content-center"
                            href="https://www.facebook.com/wuppertalerherzinitiativeev"
                            target="_blank"
                            rel="noopener noreferrer"
                        ></a>
                        <a className="text-center text-secondary" href="impressum">
                            Impressum{" "}
                        </a>
                        <a className="text-center text-secondary" href="datenschutz">
                            {" "}Datenschutz
                        </a>
                    </div>
                    <p className="text-center text-secondary">
                        © 2024 Wuppertaler Herzinitiative e.V.
                    </p>
                </div>
            </footer>

        );
    }
}
 
export default Footer;