import React, { Component } from 'react';
import Image from "../../assets/img/social.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSignature, faPaperPlane, faArrowRight, faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import File from '../../assets/Beitrittserklärung - Wuppertaler Herzinitiative e.V..pdf';
import { Helmet } from 'react-helmet';

class MembershipContent extends Component {
    render() { 
        return (
            <section className="membership">
                <Helmet>
                    <title>Mitgliedschaft - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über die Mitgliedschaftsmöglichkeiten bei der Wuppertaler Herzinitiative e.V. und laden Sie die Beitrittserklärung herunter, um Teil unserer Gemeinschaft zu werden. Als Mitglied unterstützen Sie unsere Bemühungen zur Förderung der Herzgesundheit und können aktiv an unseren Initiativen und Projekten teilnehmen." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Mitgliedschaft</h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="row mb-4 align-items-center">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="lc-block text-center">
                                <img className="img-fluid" src={Image} alt="Erleben Sie den Mehrwert einer Mitgliedschaft bei der Wuppertaler Herzinitiative e.V.: Exklusive Vorteile und starke Gemeinschaftsbindungen." loading="lazy" />
                            </div>
                        </div>
                        <div className="col-lg-6 p-lg-6">
                            <div className="lc-block mb-5" editable="rich">
                                <h2 className="display-6 fw-bold">Jetzt beitreten.</h2>
                                <p className="lead">Machen Sie mit - werden Sie Teil der Herzinitiative!</p>
                                <p>Teilen Sie unser Engagement! Helfen Sie mit bei der Verwirklichung unserer Ziele.</p>
                                <p>Wir freuen uns auf Sie!</p>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="text-primary" viewBox="0 0 16 16" lc-helper="svg-icon">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>Gutes Tun</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="text-primary" viewBox="0 0 16 16" lc-helper="svg-icon">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>Mitgestaltung der Zukunft</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" className="text-primary" viewBox="0 0 16 16" lc-helper="svg-icon">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="ms-3 align-self-center" editable="rich">
                                        <p>Netzwerk und soziale Verbindungen</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center text-center mb-4 mt-5">
                        <div className="col-lg-8 col-xxl-7">
                            <span className="text-muted">Anleitung</span>
                            <h2 className="display-5 fw-bold">So einfach geht's</h2>
                            <p className="lead">Schritt-für-Schritt-Anleitung für den Beitritt.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text-center position-relative">
                                <div className="mx-auto bg-primary border rounded-circle text-light d-flex align-items-center justify-content-center" style={{ width: '120px', height: '120px' }}>
                                    <FontAwesomeIcon icon={faPen} size="2x" />
                                </div>
                                <h4 className="mt-3 fs-5">Schritt 1</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Beitrittserklärung                         
                                <a className="text-decoration-none text-primary" href={File} target="_blank" rel="noopener noreferrer"> herunterladen</a>.</p>
                                <div className="d-none d-lg-block position-absolute" style={{ top: '50px', right: '-25px' }}>
                                    <FontAwesomeIcon icon={faArrowRight} size="3x" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-center position-relative">
                                <div className="mx-auto bg-primary border rounded-circle text-light d-flex align-items-center justify-content-center" style={{ width: '120px', height: '120px' }}>
                                    <FontAwesomeIcon icon={faSignature} size="2x" />
                                </div>
                                <h4 className="mt-3 fs-5">Schritt 2</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Beitrittserklärung ausfüllen & unterschreiben (zweimal).</p>
                                <div className="d-none d-lg-block position-absolute" style={{ top: '50px', right: '-25px' }}>
                                    <FontAwesomeIcon icon={faArrowRight} size="3x" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-center position-relative">
                                <div className="mx-auto bg-primary border rounded-circle text-light d-flex align-items-center justify-content-center" style={{ width: '120px', height: '120px' }}>
                                    <FontAwesomeIcon icon={faPaperPlane} size="2x" />
                                </div>
                                <h4 className="mt-3 fs-5">Schritt 3</h4>
                                <p className="lead text-muted mt-4 fs-5 px-lg-3 mb-5 mb-lg-0">Beitrittserklärung per  <a className="text-decoration-none text-primary" href="mailto:INFO@WUPPERTALER-HERZINITIATIVE.DE">E-Mail</a> schicken.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <a
                            className="btn btn-primary text-white"
                            href={File}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faDownload} />
                            &nbsp; Beitrittserklärung herunterladen
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default MembershipContent;