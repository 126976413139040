import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class IndexContent extends Component {
    state = {  } 
    render() { 
        return (
            <section className="info">
                <div className="container animate__animated animate__fadeInLeft">
                    <div className="bg-primary">
                        <div className="row g-0">
                            <div className="info-img col-12 col-lg-6 d-flex align-items-center overflow-hidden">
                                <div className="img-wrapper"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                                <div className="m-5">
                                    <h5 className="mb-3 h3">
                                        50% aller Todesfälle werden durch Herz-Kreislauf-Erkrankungen
                                        verursacht.
                                    </h5>
                                    <p className="mb-3 fs-5">
                                        Wir haben es uns auf die Fahne geschrieben in unserer Region an
                                        diesen Zahlen etwas zu ändern!
                                    </p>
                                    <p>
                                        Zusätzlich haben wir in Wuppertal in enger Zusammenarbeit mit dem
                                        Rettungs- und Notfalldienst ein app- und smartphone-basiertes
                                        Herzretter-System aufgebaut. Bei einem plötzlichen Herzstillstand
                                        soll erreicht werden, dass begleitend zum Notruf über 112 sich in
                                        der Nähe aufhaltende, (registrierte) ausgebildete Herzretter zu
                                        unterstützenden Rettungsmaßnahmen gerufen werden. So kann die
                                        rettende Herzdruckmassage beispielsweise schneller durchgeführt
                                        werden und helfen, die 8-10 Minuten bis zum Eintreffen
                                        professioneller Hilfe zu überbrücken.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container d-flex justify-content-center align-items-center text-center mt-5">
                        <div className="alert alert-primary" role="alert">
                            <FontAwesomeIcon icon={faInfoCircle} /> <span className="fw-bold">Neu:</span> Hier geht es zu unserer <a href="galerie" className="alert-link">Galerie</a>.
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default IndexContent;