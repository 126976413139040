import React, { Component } from 'react';
import * as texts from './texts';
import Card from './card';
import { Helmet } from 'react-helmet';

import richter_image from '../../assets/img/team/klaus_richter.jpg';
import mueller_image from '../../assets/img/team/michael_mueller.jpg';
import janguelker_image from '../../assets/img/team/jan-erik_guelker.jpg';
import berchem_image from '../../assets/img/team/nina_berchem.jpg';
import gruenig_image from '../../assets/img/team/sebastian_gruenig.jpg';

class Committees extends Component {
    state = {};

    render() {
        const { richter, mueller, janguelker, berchem, gruenig } = texts.boardOfDirectorsTexts;

        return (
            <section className="team">
                <Helmet>
                    <title>Gremien - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Informationen über die Gremien der Wuppertaler Herzinitiative e.V." />
                </Helmet>

                <section className="banner">
                    <div className="container">
                        <div className="text-center">
                            <h1 className="fw-bolder animate__animated animate__pulse">Gremien</h1>
                        </div>
                    </div>
                </section>
                <div className="container my-5">
                    <div className="row pt-4 d-flex align-items-center justify-content-center">
                        {/* Klaus H. Richter */}
                        <div className="col-md-4">
                            <div className="justify-content-md-center">
                                <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse">
                                    Geschäftsführung
                                </h2>
                                <hr className="w-50 mx-auto border-dark-subtle" />
                            </div>
                            <div className={`py-4 text-center shadow-sm`}>
                                <div className="lc-block">
                                    <img
                                        alt={`Bild von ${richter.name}`}
                                        className="rounded-circle mb-3"
                                        src={richter_image}
                                        style={{ height: "10vh" }}
                                        loading="lazy"
                                    />
                                    <h5 editable="inline">
                                        <strong>{richter.name}</strong>
                                    </h5>
                                    <small
                                        editable="inline"
                                        className="text-secondary"
                                        style={{ letterSpacing: 1 }}
                                    >
                                        {richter.role}
                                    </small>
                                </div>
                                <div className="lc-block mt-2 border-top">
                                    <div editable="rich">
                                        <p className="mt-3">
                                            {richter.bio}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Micheal Müller */}
                        <div className="col-md-4">
                            <div className="justify-content-md-center" id="mobile-spacing">
                                <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse">
                                    Kassenprüfung
                                </h2>
                                <hr className="w-50 mx-auto border-dark-subtle" />
                            </div>
                            <div className={`py-4 text-center shadow-sm`}>
                                <div className="lc-block">
                                    <img
                                        alt={`Bild von ${mueller.name}`}
                                        className="rounded-circle mb-3"
                                        src={mueller_image}
                                        style={{ height: "10vh" }}
                                        loading="lazy"
                                    />
                                    <h5 editable="inline">
                                        <strong>{mueller.name}</strong>
                                    </h5>
                                    <small
                                        editable="inline"
                                        className="text-secondary"
                                        style={{ letterSpacing: 1 }}
                                    >
                                        {mueller.role}
                                    </small>
                                </div>
                                <div className="lc-block mt-2 border-top">
                                    <div editable="rich">
                                        <p className="mt-3">
                                            {mueller.bio} <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line text-center d-flex align-items-center justify-content-center mb-2" />
                    <div className="row pt-4 d-flex align-items-center justify-content-center">
                        {/* Titel */}
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                                <h2 className="text-center fw-bold fs-4 animate__animated animate__pulse">
                                    Ärztlicher Beirat
                                </h2>
                                <hr className="w-50 mx-auto border-dark-subtle" />
                            </div>
                        </div>
                        <Card memberData={{ ...janguelker, image: janguelker_image }} />
                        <Card memberData={{ ...berchem, image: berchem_image }} />
                        <Card memberData={{ ...gruenig, image: gruenig_image }} />
                    </div>
                </div>
            </section>

        );
    }
}

export default Committees;