import React, { Component } from 'react';

class Card extends Component {
    state = {};

    render() {
        const { name, role, image, bio, fade } = this.props.memberData;

        return (
            <div className={`col-md-4 py-4 text-center shadow-sm mb-5`}>
                <div className="lc-block">
                    <img
                        alt={`Bild von ${name}`}
                        className="rounded-circle mb-3"
                        src={`${image}`}
                        style={{ height: "10vh" }}
                        loading="lazy"
                    />
                    <h5 editable="inline">
                        <strong>{name}</strong>
                    </h5>
                    <small
                        editable="inline"
                        className="text-secondary"
                        style={{ letterSpacing: 1 }}
                    >
                        {role}
                    </small>
                </div>
                <div className="lc-block mt-2 border-top" id="mobile-card">
                    <div editable="rich">
                        <p className="mt-3">
                            {bio}
                        </p>
                    </div>
                </div>
            </div>    
        );
    }
}

export default Card;