import React, { Component } from 'react';
import Article from '../article';
import { Helmet } from 'react-helmet';

class InfosHerz extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Helmet>
                    <title>Wir informieren rund ums Herz - Artikel - Wuppertaler Herzinitiative e.V.</title>
                    <meta name="description" content="Erfahren Sie mehr über den Artikel 'Wir informieren rund ums Herz', veröffentlicht am Mittwoch, 10.01.2024, bei der Wuppertaler Herzinitiative e.V. Der Artikel informiert über die Bemühungen der Herzinitiative, die Gesundheitskompetenz in Wuppertal im Bereich der Herz- und Kreislaufmedizin auszubauen. Erfahren Sie, wie die Initiative neue Wege geht, um das Angebot an Gesundheitsinformationen auszubauen, Kooperationen im Gesundheitswesen einzugehen und die Medienpräsenz zu erhöhen." />
                </Helmet>

                <Article
                    title={"Wir informieren rund ums Herz"}
                    date={"Mittwoch, 10.01.2024"}
                    tag1={"Arbeit"}
                    tag2={"Erste Hilfe"}
                    sections={
                        [
                            "Wissen ist ein gesundheitliches Wundermittel. Das gilt auch oder insbesondere für die gesundheitsrelevanten Themen der Herz- und Kreislaufmedizin. Die Herzinitiative wird daher ab 2024 noch mehr Beiträge leisten, um die Gesundheitskompetenz in Wuppertal auszubauen. Es kann nicht angehen, dass nahezu die Mehrheit der Bevölkerung nur über eine eingeschränkte Gesundheitskompetenz verfügt. ",
                            "Dazu arbeiten wir an neuen Wegen, um das an sich je schon große Angebot an Gesundheitsinformationen regional auszubauen und weiter zu entwickeln. Dazu sind enge Kooperationen mit Leistungsträgern im Gesundheitswesen und diversen Einrichtungen der sozialen und gesundheitlichen Sicherung vorgesehen. So werden wir für mehr gesundheitliches Wissen um Herz und Kreislauf sorgen.",
                            "Wir bauen unsere Medienarbeit aus und werden  stärker auf soziale Medien setzen. Ein Ausbau der Kooperation mit der Deutschen Herzstiftung wird angestrebt; auch bei den Herzwochen 2024 im November. Die bewährte Veranstaltungsarbeit wird noch ausgebaut. Interessante themenzentrierte Veranstaltungen (z.B, Plötzlicher Herztod oder Herzerkankungen und Diabetes; Geschlechterspezifische Kardiologie). "
                        ]
                    }
                    overview={"aus-unserer-arbeit-neu"}
                /> 
            </>
        );
    }
}
 
export default InfosHerz;